import { Component, OnDestroy, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { UserAccountService } from './core/service/user-account.service';
import { Subscription } from 'rxjs';
import { ToastIntersect } from './core/service/toast-intersect.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [MessageService]
})
export class AppComponent implements OnInit, OnDestroy{
  title = 'jeromus-angular';
  public tokenSub: Subscription;
  private toastSubsription: Subscription;
  public timeOut: any;

  constructor(private userAccountSerivce: UserAccountService, private messageService: MessageService, private toastIntersect: ToastIntersect){}

  async ngOnInit(): Promise<void> {
    this.toastSubsription = this.toastIntersect.getToastObservable().subscribe(val =>{
      this.messageService.add({severity: val.type, summary: val.summary, detail: val.detail})
    })

    this.tokenSub = this.userAccountSerivce.getUserDataSubscription().subscribe((val) =>{
      let userToken = {accessToken: this.userAccountSerivce.getCookie("accessToken"), 
        refreshToken: this.userAccountSerivce.getCookie("refreshToken"),
        accessTokenExpirationTime: this.userAccountSerivce.getCookie("accessTokenExpirationTime")
      }
      
      if(this.timeOut){
        clearInterval(this.timeOut);
      }

      this.timeOut = setInterval(async () =>{
        userToken = {accessToken: this.userAccountSerivce.getCookie("accessToken"), 
          refreshToken: this.userAccountSerivce.getCookie("refreshToken"),
          accessTokenExpirationTime: this.userAccountSerivce.getCookie("accessTokenExpirationTime")
        }

        if(new Date(userToken.accessTokenExpirationTime).getTime() - Date.now() < 35000){
          await this.userAccountSerivce.refreshCookie();
        }
      }, 30000)
    })
  }

  ngOnDestroy(): void {
    this.tokenSub.unsubscribe();
    this.toastSubsription.unsubscribe();
    clearInterval(this.timeOut);
  }
}
