import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { UserAccountService } from '../service/user-account.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardGuard implements CanActivate {
  constructor(private userService: UserAccountService, private router: Router) {}
  
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
      const userExist = await this.userService.getAccountCheck();

      if (userExist) {
        return true;
      } else {
        this.router.navigate(["/"]);
        return false;
      }
  }
  
}
