import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToastIntersect {
    private toastSubject = new Subject<any>();
    constructor() { }

    public getToastObservable() {
        return this.toastSubject.asObservable();
    }

    public async showToast(type:any, summary: string, detail?: string) {
        this.toastSubject.next({ type: type, summary: summary, detail: detail });
      }
}
