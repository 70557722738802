import { SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/enviroments/environment';
import { BehaviorSubject, Observable, Subject, lastValueFrom } from 'rxjs';
import { userToken } from '../model/userToken.model';
import { UserModel } from '../model/user.model';
import { ToastIntersect } from './toast-intersect.service';

@Injectable({
  providedIn: 'root'
})
export class UserAccountService {
  public user: SocialUser = null;
  public logedWithGoogle: boolean = false;
  public apiURL = environment.apiUrl;
  private userData = new BehaviorSubject<userToken>(new userToken());

  constructor(private http: HttpClient, private toastIntersect: ToastIntersect) { }

  public async getAccountCheck(): Promise<boolean>{
    try {
      const headers = new HttpHeaders({
        'Authorization': `Bearer ${this.getCookie('accessToken')}`
      });

      const requestOptions = {
        headers: headers,
      };

      const response = await this.http.head<any>(this.apiURL + `/account/check`, { observe: 'response', headers: headers }).toPromise();

      if ((response.status + '').startsWith('2')) {
        return true;
      } else {
        return false;
      }

    } catch (error) {
      console.error('Error fetching account check:', error);
      this.toastIntersect.showToast("error", "Checking account", "Error accured during account cookie checkout!");
      return false;
    }
  }


  public setUser(user: SocialUser){
    this.logedWithGoogle = user != null;
    this.user = user;
  }

  public isLogged(): boolean{
    return this.user != null;
  }

  public getUserDataSubscription(){
    return this.userData;
  }

  public async logIn(user: SocialUser){
    const $response = this.http.post(this.apiURL+"/account/external-login", user, {withCredentials: true});
    let response = await lastValueFrom($response).catch(err => {return null;});
    this.userData.next(response);
    document.cookie=`accessToken=${response.accessToken}; expires=${response.accessTokenExpirationTime}; path=/`;
    document.cookie=`refreshToken=${response.refreshToken}; expires=${response.accessTokenExpirationTime}; path=/`;
    document.cookie=`accessTokenExpirationTime=${response.accessTokenExpirationTime}; expires=${response.accessTokenExpirationTime}; path=/`;
    return this.logedWithGoogle;
  }

  public async refreshCookie(){
    const $response = this.http.post(this.apiURL+"/account/refresh", {"RefreshToken": this.getCookie("refreshToken")}, {withCredentials: true});
    let response = await lastValueFrom($response).catch(err => {return null;});
    this.userData.next(response);

    document.cookie=`accessToken=${response.accessToken}; expires=${response.accessTokenExpirationTime}; path=/`;
    document.cookie=`refreshToken=${response.refreshToken}; expires=${response.accessTokenExpirationTime}; path=/`;
    document.cookie=`accessTokenExpirationTime=${response.accessTokenExpirationTime}; expires=${response.accessTokenExpirationTime}; path=/`;
    return;
  }
  
  public async logOut(){
    if(this.hasCookie()){
      console.log("exists");
    }

    const $response = this.http.post(this.apiURL+"/Auth/logout", {withCredentials: true});
    let response = await lastValueFrom($response).catch(err => {return null;});
    return response;
  }

  public hasCookie(){
    let name ="accessToken"
    return document.cookie.split(';').some(c => {
      return c.trim().startsWith(name + '=');
    });
  }

  public getCookie(name: string){
    let cookies = document.cookie.split(";");
    cookies = cookies.map((str) => str.trim());

    for(let cookie of cookies){
      if(cookie.startsWith(name+"=")){
        return cookie.split("=")[1];
      }
    }

    return ""
  }

  public async logWithPass(){
    const $response = this.http.post(this.apiURL+"/account/login", {email:"string1@gmail.com", password: "string123"}, {withCredentials: true});
    let response = await lastValueFrom($response).catch(err => {return null;});
    this.userData.next(response);
    document.cookie=`accessToken=${response.accessToken}; expires=${response.accessTokenExpirationTime}; path=/`;
    document.cookie=`refreshToken=${response.refreshToken}; expires=${response.accessTokenExpirationTime}; path=/`;
    document.cookie=`accessTokenExpirationTime=${response.accessTokenExpirationTime}; expires=${response.accessTokenExpirationTime}; path=/`;
    
    return true;
  }
}
