import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardGuard } from './core/guards';

const routes: Routes = [
  {path: 'dashboard', loadChildren: () => import('./dashboard').then(m => m.DashboardModule), canActivate: [DashboardGuard]},
  {path: 'dashboard3d', loadChildren: () => import('./dashboard3d').then(m => m.Dashboard3dModule), canActivate: [DashboardGuard]},
  {path: '', loadChildren: () => import('./login').then(m => m.LoginModule)},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
